body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  border: solid 1.5px #DDDDDD;
  background-color: #ffffff;
  width: 460px;
  height: 700px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.textarea{
  border: solid 1px #D9D9D9;
  height: 10vh;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 14px;
}